import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { SubscribeService } from './subscribe.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  constructor(private subscribeService:SubscribeService, private translate: TranslateService) { }
   url:any;

  ngOnInit(): void {
    
  this.subscribeService.urlStripe.subscribe(url => {
    this.url = url;
  })
  
  }



 


}
