import { Component, OnInit } from '@angular/core';
import { Deliveryfees, OrderSettings } from './delivery-fees.model';
import { NgxIzitoastService } from 'ngx-izitoast';
import { FeesService } from '../fees.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionManagerService } from '../../session-manager/session-manager.service';
@Component({
  selector: 'app-delveryfees-eat',
  templateUrl: './delivery-fees.component.html',
  styleUrls: ['./delivery-fees.component.css'],
})
export class DeliveryFeesComponent implements OnInit {
  loading = false;
  deliveryfees: Deliveryfees = {};
  orderSettings: OrderSettings = {}
  constructor(
    private feesService: FeesService,
    private translate: TranslateService,
    public iziToast: NgxIzitoastService,
    private sessionManagerService: SessionManagerService
  ) { }

  ngOnInit(): void {
    this.loadDeliveryFees();
    this.loadOrderSettings();
  }

  loadDeliveryFees() {
    this.feesService.getAll().subscribe((delivery) => {
      this.deliveryfees = delivery;
    });
  }

  loadOrderSettings() {
    this.feesService.getOrderSettings().subscribe((orderSettings) => {
      //console.log(orderSettings)
      this.orderSettings = orderSettings
    });
  }

  Update_delivery_fees() {
    this.loading = true;
    this.feesService.updateDeliveryFees(this.deliveryfees).subscribe(
      (response) => {
        this.iziToast.success({
          message: this.translate.instant('fees.updated'),
          position: 'topCenter',
          toastOnce: true,
        });
        this.loading = false;
        this.loadDeliveryFees();
      },
      (error) => {
        //console.log(error);
        this.loading = false;
      }
    );
  }

  updateOrderSettings() {
    this.loading = true;
    let request: any = {
      min_price: this.orderSettings.min_price,
      // is_order_now: this.orderSettings.is_order_now ?? false,
      // is_order_another_day: this.orderSettings.is_order_another_day ?? false,
    }
    this.feesService.updateOrderSettings(request).subscribe(
      (response) => {
        this.iziToast.success({
          message: this.translate.instant('fees.updated-order-settings'),
          position: 'topCenter',
          toastOnce: true,
        });
        this.loading = false;
        this.loadOrderSettings();
      },
      (error) => {
        //console.log(error);
        this.loading = false;
      }
    );
  }


}
