import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { Subscribe } from '../subscribe.model';
import { SubscribeService } from '../subscribe.service';
import { ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-stripe-active',
  templateUrl: './stripe-active.component.html',
  styleUrls: ['./stripe-active.component.scss']
})
export class StripeActiveComponent implements OnInit {
  
  id:string; 
  success:string;  
  accountActive : string;
  subscribe:Subscribe;
  link:string;
  working:boolean = false;
  constructor(private subscribeService:SubscribeService,private route: ActivatedRoute, private translate: TranslateService) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.id = params.get("stipe_id");
    });
    this.acountStripeActive();

  }



  acountStripeActive() {
    this.subscribeService.acountStripeActive(this.id).subscribe(item => {
      //console.log(item);
      this.working=true;
         this.success = item.success;
         this.accountActive  = item.account;
         this.link = item.link.url;
         if (this.success){
          localStorage.removeItem(environment.currentUserIndex);
          localStorage.removeItem(environment.tokenIndex);
          localStorage.removeItem(environment.profileCompletedIndex);
         // this.router.navigate(['auth']);
         }
         //this.link = item.link.url;
    }, (error) => {
        this.working=true;

       //  this.success = false;

    })
  }




}
