import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Restaurant } from 'src/app/core/session-manager/session-manager.model';
import { SessionManagerService } from 'src/app/core/session-manager/session-manager.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthGuard } from '../../authentification/auth-guard.service';
import { TranslateService } from '@ngx-translate/core';
import { removeitem, right_to_left, left_to_right, change } from '../animations/animations';
declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [removeitem, right_to_left, left_to_right, change]
})
export class SidebarComponent implements OnInit {

    restaurant: any;
    poweredby_logo: any;
    show: boolean = false;
    show1: boolean = false;
    show2: boolean = false;
    show3: boolean = false;
    show4: boolean = false;
    show5: boolean = false;
    show6: boolean = false;
    selected: boolean = false;
    selected1: boolean = false;
    selected2: boolean = false;
    selected3: boolean = false;
    selected4: boolean = false;
    selected5: boolean = false;
    selected6: boolean = false;
    showPowertable = environment.powertable;
    private cdn = environment.cdn;

    @ViewChild('toggleButton1') toggleButton1: ElementRef;
    @ViewChild('toggleButton2') toggleButton2: ElementRef;
    @ViewChild('toggleButton3') toggleButton3: ElementRef;
    @ViewChild('toggleButton4') toggleButton4: ElementRef;
    @ViewChild('toggleButton5') toggleButton5: ElementRef;
    @ViewChild('toggleButton6') toggleButton6: ElementRef;
    @ViewChild('toggleButton7') toggleButton7: ElementRef;

    menuElement: any[] = [];
    levelOne: number = null;
    levelTwo: number = null;
    constructor(private sessionManagerService: SessionManagerService, private route: Router, public guard: AuthGuard, private translate: TranslateService) {
        //console.log(route.url);

        route.events.subscribe(val => {
            this.autoselectalreadyactive();
        });
    }
    ngOnInit() {
        $.getScript('./assets/vendors/core/core.js');
        $.getScript('./assets/vendors/feather-icons/feather.min.js');
        $.getScript('./assets/js/template.js');
        this.restaurant = [];
        this.loadCurrentRestaurant();


        this.menuElement = [
            { id: 0, name: this.translate.instant('navigation.commande'), icon: 'assets/images/svg/transactions.svg', link: '/client/orders/list', items: null, display: true },
            {
                id: 1, name: this.translate.instant('navigation.programmation'), icon: 'assets/images/svg/Customers.svg', link: null, items:
                    [
                        { id: 0, name: this.translate.instant('navigation.category_management'), icon: null, link: '/client/category/list', display: true },
                        { id: 1, name: this.translate.instant('navigation.product_management'), icon: null, link: '/client/category/products', display: true },
                        { id: 2, name: this.translate.instant('navigation.supplements'), icon: null, link: '/client/category/supplements', display: true },
                        { id: 3, name: this.translate.instant('navigation.ingredient'), icon: null, link: '/client/category/ingredients', display: true },
                        { id: 3, name: this.translate.instant('navigation.blocs'), icon: null, link: '/client/category/blocs', display: true },
                        { id: 4, name: this.translate.instant('navigation.table_management'), icon: null, link: '/client/tables/list', display: this.guard.checkpermission(['GuestTableOrder']) },
                        { id: 5, name: this.translate.instant('navigation.server_management'), icon: null, link: '/client/servers/list', display: this.guard.checkpermission(['GuestTableOrder']) },
                        { id: 6, name: this.translate.instant('navigation.order_predefined'), icon: null, link: '/client/orders/order-predefined', display: this.guard.checkpermission(['GuestTableOrder']) }

                    ], display: true

            },
            {
                id: 2, name: this.translate.instant('navigation.gestion'), icon: 'assets/images/svg/Subscriptions.svg', link: null, items:
                    [
                        { id: 0, name: this.translate.instant('navigation.news_management'), icon: null, link: '/client/news/list', display: true },
                        { id: 1, name: this.translate.instant('navigation.menu-of-day'), icon: null, link: '/client/category/menu-of-day', display: true },
                        { id: 2, name: this.translate.instant('navigation.note_restaurant'), icon: null, link: '/client/notes/list', display: true },
                        { id: 3, name: this.translate.instant('navigation.note_waiter'), icon: null, link: '/client/notes/waiters', display: true },
                        { id: 4, name: this.translate.instant('navigation.adresse'), icon: null, link: '/client/session-manager/adresses', display: true },
                        // {id: 5 , name :  this.translate.instant('navigation.coupons'), icon : null, link : '/client/servers/list', display: true},
                        // {id: 6, name :  this.translate.instant('navigation.menus_product'), icon : null, link : '/client/category/menu-product', display: true}

                    ], display: true
            },
            {
                id: 3, name: this.translate.instant('navigation.configuration'), icon: 'assets/images/svg/Balances.svg', link: null, items:
                    [
                        { id: 0, name: this.translate.instant('navigation.personnalisation'), icon: null, link: '/client/session-manager/personnalisation', display: true },
                        { id: 1, name: this.translate.instant('navigation.day_code'), icon: null, link: '/client/day-code/day-code', display: true },
                        { id: 2, name: this.translate.instant('navigation.WorkTime'), icon: null, link: '/client/workTime/list', display: true },
                        { id: 3, name: this.translate.instant('navigation.device'), icon: null, link: '/client/device/list', display: this.guard.checkpermission(['GuestTablePower']) },
                        { id: 4, name: this.translate.instant('navigation.touchscreen'), icon: null, link: '/client/touchscreen/list', display: true },
                        { id: 5, name: this.translate.instant('navigation.tablet'), icon: null, link: '/client/tablet/list', display: true },
                        { id: 6, name: this.translate.instant('navigation.notification'), icon: null, link: '/client/notification/list', display: true },
                        { id: 7, name: this.translate.instant('navigation.option'), icon: null, link: '/client/session-manager/options', display: true },
                        { id: 8, name: this.translate.instant('navigation.delivery-fees'), icon: null, link: '/client/order/settings', display: true }

                    ], display: true
            },
            {
                id: 4, name: this.translate.instant('navigation.cash_register'), icon: 'assets/images/svg/Payouts.svg', link: null, items:
                    [
                        // { id: 0, name: this.translate.instant('navigation.upost'), icon: null, link: '/client/cash-register/list', display: true },
                        { id: 0, name: this.translate.instant('navigation.fulle_pos'), icon: null, link: '/client/fulle-pos/list', display: true },
                        { id: 1, name: this.translate.instant('navigation.chr'), icon: null, link: '/client/chr/list', display: true }

                    ], display: true
            },
            // {
            //     id: 5, name: this.translate.instant('navigation.partners'), icon: 'assets/images/svg/Balances.svg', link: null, items:
            //         [
            //             { id: 0, name: this.translate.instant('navigation.uber-eats'), icon: null, link: '/client/partners/ubereat', display: true }

            //         ], display: true
            // },
            {
                id: 5, name: this.translate.instant('navigation.partners'), icon: 'assets/images/svg/Balances.svg', link: null, items:
                    [
                        { id: 0, name: this.translate.instant('navigation.hubrise'), icon: null, link: '/client/partners/hubrise', display: true }


                    ], display: true
            }
            //{name : this.translate.instant("navigation.documents"), icon : 'assets/images/svg/Payment_Plans.svg' , link : '/client/documents/list' , items: null, display: true }
        ];
        this.autotoggle();
    }

    isProfileCompleted() {
        return this.sessionManagerService.isProfileCompleted();
    }

    openNav(event: Event) {
        if (this.toggleButton1.nativeElement.contains(event.target)) {

            this.show = !this.show;
            this.show1 = false;
            this.show2 = false;
            this.show3 = false;
            this.show4 = false;
            this.show5 = false;
            this.show6 = false;
        } else if (this.toggleButton2.nativeElement.contains(event.target)) {

            this.show1 = !this.show1;
            this.show = false;
            this.show2 = false;
            this.show3 = false;
            this.show4 = false;
            this.show5 = false;
            this.show6 = false;
        } else if (this.toggleButton3.nativeElement.contains(event.target)) {

            this.show2 = !this.show2;
            this.show = false;
            this.show1 = false;
            this.show3 = false;
            this.show4 = false;
            this.show5 = false;
            this.show6 = false;
        } else if (this.toggleButton4.nativeElement.contains(event.target)) {

            this.show3 = !this.show3;
            this.show = false;
            this.show1 = false;
            this.show2 = false;
            this.show4 = false;
            this.show5 = false;
            this.show6 = false;
        } else if (this.toggleButton5.nativeElement.contains(event.target)) {

            this.show4 = !this.show4;
            this.show = false;
            this.show1 = false;
            this.show2 = false;
            this.show3 = false;
            this.show5 = false;
            this.show6 = false;
        } else if (this.toggleButton6.nativeElement.contains(event.target)) {
            this.show5 = !this.show5;
            this.show = false;
            this.show1 = false;
            this.show2 = false;
            this.show3 = false;
            this.show4 = false;
            this.show6 = false;
        }
        else if (this.toggleButton7.nativeElement.contains(event.target)) {
            this.show6 = !this.show6;
            this.show = false;
            this.show1 = false;
            this.show2 = false;
            this.show3 = false;
            this.show4 = false;
            this.show5 = false;
        }


    }

    loadCurrentRestaurant() {
        this.sessionManagerService.getCurrentRestaurant().subscribe(restaurant => {
            this.restaurant = restaurant;
            this.poweredby_logo = this.cdn + restaurant.poweredby_logo;
        })
    }
    autotoggle() {
        this.menuElement.forEach((element) => {
            if (element.items && element.items.length > 0) {
                element.items.forEach((susele) => {

                    if (this.route.url.indexOf(susele.link) !== -1) {
                        this.levelOne = element.id;
                        this.levelTwo = susele.id;

                    }
                });
            }

        });
        return null;
        if (this.route.url.indexOf('/client/orders/list') !== -1 || this.route.url.indexOf('/client/order-history/list') !== -1) {
            this.show = true;
            this.selected = true;
            this.selected1 = false;
            this.selected2 = false;
            this.selected3 = false;
            this.selected4 = false;
            this.selected5 = false;
            this.selected6 = false;
            this.levelOne = 1;
        }
        if (this.route.url.indexOf('/client/category/list') !== -1 || this.route.url.indexOf('/client/category/products') !== -1 || this.route.url.indexOf('/client/category/supplements') !== -1 || this.route.url.indexOf('/client/category/ingredients') !== -1 || this.route.url.indexOf('/client/tables/list') !== -1 || this.route.url.indexOf('/client/servers/list') !== -1 || this.route.url.indexOf('/client/orders/order-predefined') !== -1) {
            this.show1 = true;
            this.selected = false;
            this.selected1 = true;
            this.selected2 = false;
            this.selected3 = false;
            this.selected4 = false;
            this.selected5 = false;
            this.selected6 = false;
            this.levelOne = 2;
        }
        if (this.route.url.indexOf('/client/news/list') !== -1 || this.route.url.indexOf('/client/category/menu-of-day') !== -1 || this.route.url.indexOf('/client/notes/list') !== -1 || this.route.url.indexOf('/client/notes/waiters') !== -1 || this.route.url.indexOf('/client/session-manager/adresses') !== -1 || this.route.url.indexOf('/client/coupons/list') !== -1 || this.route.url.indexOf('/client/category/menu-product') !== -1) {
            this.show2 = true;
            this.selected = false;
            this.selected1 = false;
            this.selected2 = true;
            this.selected3 = false;
            this.selected4 = false;
            this.selected5 = false;
            this.selected6 = false;
            this.levelOne = 3;
        }
        if (this.route.url.indexOf('/client/workTime/list') !== -1 || this.route.url.indexOf('/client/day-code/day-code') !== -1 || this.route.url.indexOf('/client/session-manager/personnalisation') !== -1 || this.route.url.indexOf('/client/device/list') !== -1 || this.route.url.indexOf('/client/notification/list') !== -1) {
            this.show3 = true;
            this.selected = false;
            this.selected1 = false;
            this.selected2 = false;
            this.selected3 = true;
            this.selected4 = false;
            this.selected5 = false;
            this.selected6 = false;
            this.levelOne = 4;
        }
        if (this.route.url.indexOf('/client/cash-register/list') !== -1 || this.route.url.indexOf('/client/chr/list') !== -1) {
            this.show4 = true;
            this.selected = false;
            this.selected1 = false;
            this.selected2 = false;
            this.selected3 = false;
            this.selected4 = true;
            this.selected5 = false;
            this.selected6 = false;
        }

        if (this.route.url.indexOf('/client/partners/ubereat') !== -1) {
            this.show6 = true;
            this.selected = false;
            this.selected1 = false;
            this.selected2 = false;
            this.selected3 = false;
            this.selected4 = false;
            this.selected5 = false;
            this.selected6 = true;
        }

        if (this.route.url.indexOf('/client/documents/list') !== -1) {
            this.show5 = true;
            this.selected = false;
            this.selected1 = false;
            this.selected2 = false;
            this.selected3 = false;
            this.selected4 = false;
            this.selected5 = true;
            this.selected6 = false;
        }
    }
    autoselectalreadyactive() {
        if (this.route.url.indexOf('/client/orders/list') !== -1 || this.route.url.indexOf('/client/order-history/list') !== -1) {
            this.selected = true;
            this.selected1 = false;
            this.selected2 = false;
            this.selected3 = false;
            this.selected4 = false;
            this.selected5 = false;
            this.selected6 = false;
        }
        if (this.route.url.indexOf('/client/category/list') !== -1 || this.route.url.indexOf('/client/category/products') !== -1 || this.route.url.indexOf('/client/category/supplements') !== -1 || this.route.url.indexOf('/client/category/ingredients') !== -1 || this.route.url.indexOf('/client/tables/list') !== -1 || this.route.url.indexOf('/client/servers/list') !== -1 || this.route.url.indexOf('/client/orders/order-predefined') !== -1) {
            this.selected = false;
            this.selected1 = true;
            this.selected2 = false;
            this.selected3 = false;
            this.selected4 = false;
            this.selected5 = false;
            this.selected6 = false;
        }
        if (this.route.url.indexOf('/client/news/list') !== -1 || this.route.url.indexOf('/client/category/menu-of-day') !== -1 || this.route.url.indexOf('/client/notes/list') !== -1 || this.route.url.indexOf('/client/notes/waiters') !== -1 || this.route.url.indexOf('/client/session-manager/adresses') !== -1 || this.route.url.indexOf('/client/coupons/list') !== -1 || this.route.url.indexOf('/client/category/menu-product') !== -1) {
            this.selected = false;
            this.selected1 = false;
            this.selected2 = true;
            this.selected3 = false;
            this.selected4 = false;
            this.selected5 = false;
            this.selected6 = false;
        }
        if (this.route.url.indexOf('/client/session-manager/personnalisation') !== -1 || this.route.url.indexOf('/client/device/list') !== -1 || this.route.url.indexOf('/client/notification/list') !== -1) {
            this.selected = false;
            this.selected1 = false;
            this.selected2 = false;
            this.selected3 = true;
            this.selected4 = false;
            this.selected5 = false;
            this.selected6 = false;
        }
        if (this.route.url.indexOf('/client/cash-register/list') !== -1 || this.route.url.indexOf('/client/chr/list') !== -1) {
            this.selected = false;
            this.selected1 = false;
            this.selected2 = false;
            this.selected3 = false;
            this.selected4 = true;
            this.selected5 = false;
            this.selected6 = false;
        }
        if (this.route.url.indexOf('/client/workTime/list') !== -1) {
            this.selected = false;
            this.selected1 = false;
            this.selected2 = false;
            this.selected3 = false;
            this.selected4 = false;
            this.selected5 = false;
            this.selected6 = true;
        }
        if (this.route.url.indexOf('/client/documents/list') !== -1) {
            this.selected = false;
            this.selected1 = false;
            this.selected2 = false;
            this.selected3 = false;
            this.selected4 = false;
            this.selected5 = true;
            this.selected6 = false;
        }
    }

    click_event(element) {
        this.levelOne = element.id;

        if (!!element.link) {
            this.route.navigate([element.link]);
        } else {
            // this.route.navigate([element.link]);
        }
    }
    clickEventLvlTwo(element) {
        this.levelTwo = element.id;
        if (!element.link) {
            this.route.navigate([element.link]);
        } else {
            this.route.navigate([element.link]);
        }
    }
    home(elements) {
        this.levelTwo = null;
        this.levelOne = null;
    }

    elementsInside(element): boolean {
        if (element.link && this.route.url.indexOf(element.link) !== -1) return true;

        if (element.items && element.items.length > 0)
            for (var i = 0; i < element.items.length; i++)
                if (element.items[i].link && this.route.url.indexOf(element.items[i].link) !== -1) {

                    return true;
                }

        return false;
    }

    getfirstlettre(name) {
        return name.split('@').map(n => n[0] + '' + n[1] + '' + n[2] + '' + n[3] + "...").join('');
    }
}
