import { Component, OnInit } from '@angular/core';
import { Ubereats } from './ubereats.model';
import { NgxIzitoastService } from 'ngx-izitoast';
import { PartnersService } from '../partners.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionManagerService } from '../../session-manager/session-manager.service';
import { ConfirmationService } from 'primeng/api';
@Component({
    selector: 'app-uber-eat',
    templateUrl: './uber-eat.component.html',
    styleUrls: ['./uber-eat.component.css'],
    providers: [ConfirmationService]
})
export class UberEatComponent implements OnInit {
    loading = false;
    ubereats: Ubereats = {};
    //uber_integration: boolean = false;
    hubrise_tab: number = 1;
    hubrise_token: string = ""

    constructor(private partnerservices: PartnersService,
        private translate: TranslateService,
        public iziToast: NgxIzitoastService,
        private confirmationService: ConfirmationService,
        private sessionManagerService: SessionManagerService) { }

    ngOnInit(): void {
        this.loadCurrentRestaurant();
    }

    loadCurrentRestaurant() {
        this.sessionManagerService.getCurrentRestaurant().subscribe(restaurant => {
            if (restaurant.codeRestaurant) {
                this.ubereats.restaurantUberEatId = restaurant.restaurantUberEatId;
                //this.ubereats.vendorCode = restaurant.vendorCode;
                //this.uber_integration = restaurant.ubereats_integration;
            }
        })
    }


    Update_ubereats() {
        this.loading = true
        console.log(this.ubereats)
        this.partnerservices.updateUberEats(this.ubereats).subscribe(response => {
            this.iziToast.success({

                message: 'les informations de uber eats a été modifié avec succès ',
                position: 'topCenter',
                toastOnce: true
            });
            this.loading = false;
            this.loadCurrentRestaurant();
        }, (error) => {
            //console.log(error);
            this.loading = false;
        });

    }
    onSynchronise() {
        this.loading = true;
        this.partnerservices.synchroniseuber().subscribe(
            (items) => {





                this.iziToast.success({
                    message: this.translate.instant(
                        'global.sync_element_success',

                    ),
                    position: 'topCenter',
                    toastOnce: true,
                });
                this.loading = false;


            },
            (error) => {
                this.loading = false;
                throw error;
            }
        );
    }
    onPushArticles() {
        this.loading = true;
        this.partnerservices.pushArticlesuber().subscribe(
            (items) => {
                if (items.success) {
                    this.iziToast.success({
                        message: this.translate.instant(
                            'global.return_ok',

                        ),
                        position: 'topCenter',
                        toastOnce: true,
                    });
                    this.loading = false;
                } else {
                    this.iziToast.warning({
                        message: this.translate.instant(
                            'global.return_failed',

                        ),
                        position: 'topCenter',
                        toastOnce: true,
                    });
                    this.loading = false;
                }




            },
            (error) => {
                this.loading = false;
                throw error;
            }
        );
    }
    onDeIntegration() {
        //console.log("we are corking");
        this.confirmationService.confirm({
            key: 'mainDialog',
            message: 'some message',
            header: 'Warning',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'ok',
            rejectVisible: false,
            accept: () => { },
        });

        this.confirmationService.confirm({
            message: "Vous êtes sur le point de désintégrer uber eats êtes-vous sûr de vouloir continuer",
            accept: () => {
                this.onIntegration();

            }
        });
    }

    onIntegration() {

        this.loading = true;
        this.partnerservices.integrationuber().subscribe(
            (items) => {

                if (items.success) {
                    this.iziToast.success({
                        message: this.translate.instant(
                            'global.return_ok',

                        ),
                        position: 'topCenter',
                        toastOnce: true,
                    });
                    if (items.url) {
                        window.open(items.url, "_blank");
                    }
                    this.loading = false;
                    this.loadCurrentRestaurant();
                } else {
                    this.iziToast.warning({
                        message: this.translate.instant(
                            'global.return_failed',

                        ),
                        position: 'topCenter',
                        toastOnce: true,
                    });
                    this.loading = false;
                }




            },
            (error) => {
                this.loading = false;
                throw error;
            }
        );
    }
    onStatus() {
        this.loading = true;
        this.partnerservices.statusuber().subscribe(
            (items) => {

                if (items.success) {
                    this.iziToast.success({
                        message: this.translate.instant(
                            'global.return_ok',

                        ),
                        position: 'topCenter',
                        toastOnce: true,
                    });
                    this.loading = false;
                } else {
                    this.iziToast.warning({
                        message: this.translate.instant(
                            'global.return_failed',

                        ),
                        position: 'topCenter',
                        toastOnce: true,
                    });
                    this.loading = false;
                }



            },
            (error) => {
                this.loading = false;
                throw error;
            }
        );
    }


    onGetHubriseURL() {
        this.loading = true;
        this.partnerservices.getHubriseUrl().subscribe(
            (hubrise) => {
                console.log(hubrise)
                if (hubrise?.link) {
                    this.hubrise_tab = 2
                    window.open(hubrise.link, "_blank");
                    this.loading = false;
                }
            },
            (error) => {
                this.loading = false;
                throw error;
            }
        );
    }
    UpdateHubriseToken() {
        this.loading = true
        this.partnerservices.updateHubriseToken({ token: this.hubrise_token }).subscribe(response => {
            this.iziToast.success({
                message: this.translate.instant(
                    'global.return_ok',
                ),
                position: 'topCenter',
                toastOnce: true,
            });
            this.loading = false;
            this.loadCurrentRestaurant();
        }, (error) => {
            //
            if (error?.status == 400) {
                this.iziToast.warning({
                    message: this.translate.instant(
                        'global.return_failed',
                    ),
                    position: 'topCenter',
                    toastOnce: true,
                });
            }
            this.loading = false;
        });

    }
}
