import { AuthGuard } from './authentification/auth-guard.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Full_ROUTES } from './shared/routes/full-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';
import { AuthentificationComponent } from './authentification/authentification.component';
import { FullLayoutComponent } from './core/full-layout.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { EmailVerifyComponent } from './subscribe/email-verify/email-verify.component';
import { PaimentComponent } from './subscribe/paiment/paiment.component';
import { ChoosePlanComponent } from './subscribe/choose-plan/choose-plan.component';
import { StripeRefreshComponent } from './subscribe/stripe-refresh/stripe-refresh.component';
import { StripeActiveComponent } from './subscribe/stripe-active/stripe-active.component';


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'client',
    component: FullLayoutComponent,
    children: Full_ROUTES,
    canActivate: [AuthGuard]
  }, {
    path: 'api',
    loadChildren: './api/api.module#ApiModule'
  },
  {
    path: 'auth',
    component: AuthentificationComponent,
    children: CONTENT_ROUTES
  },
  {
    path: 'email/verify/:id/:token',
    component: EmailVerifyComponent,
  },
  {
    path: 'subscribe',
    component: SubscribeComponent,
  },
  {
    path: 'choose-plan',
    component: ChoosePlanComponent,
  },
  {
    path: 'paiment',
    component: PaimentComponent,
  },
  {
    path: 'return/:stipe_id',
    component: StripeActiveComponent,
  },
  {
    path: 'refresh',
    redirectTo: 'auth',
    //component: StripeRefreshComponent,
  },
  { path: '**', component: AuthentificationComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
