import { Routes } from '@angular/router';
import { PartnersRoutingModule } from '../../core/partners/partners-routing.module';
import { AuthGuard } from '../../authentification/auth-guard.service';
import { subscriptions_name } from '../../authentification/authentification.model';
import { FeesRoutingModule } from '../../core/fees/fees-routing.module';
// Route for content layout with sidebar, navbar and footer.
export const Full_ROUTES: Routes = [
  {
    path: 'orders',
    loadChildren: () => import('../../core/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'servers',
    loadChildren: () => import('../../core/servers/servers.module').then(m => m.ServersModule),
    canActivate: [AuthGuard],
    data: { roles: [subscriptions_name.GuestTableOrder] }
  },
  {
    path: 'session-manager',
    loadChildren: () => import('../../core/session-manager/session-manager.module').then(m => m.SessionManagerModule)
  },
  {
    path: 'tables',
    loadChildren: () => import('../../core/tables/tables.module').then(m => m.TablesModule),
    canActivate: [AuthGuard],
    data: { roles: [subscriptions_name.GuestTableOrder] }
  },
  {
    path: 'notes',
    loadChildren: () => import('../../core/notes/notes.module').then(m => m.NotesModule)
  },
  {
    path: 'category',
    loadChildren: () => import('../../core/products/category.module').then(m => m.CatgeoryModule)
  },
  {
    path: 'news',
    loadChildren: () => import('../../core/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'day-code',
    loadChildren: () => import('../../core/day-code/day-code.module').then(m => m.DayCodeModule)
  },
  {
    path: 'order-history',
    loadChildren: () => import('../../core/order-history/order-history.module').then(m => m.OrderHistoryModule)
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../core/coupons/coupons.module').then(m => m.CouponsModule)
  },
  {
    path: 'order-predefined',
    loadChildren: () => import('../../core/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'cash-register',
    loadChildren: () => import('../../core/cash-register/cash-register.module').then(m => m.CashRegisterModule)
  },
  {
    path: 'device',
    loadChildren: () => import('../../core/device/device.module').then(m => m.DeviceModule),
    canActivate: [AuthGuard],
    data: { roles: [subscriptions_name.GuestTablePower] }
  },
  {
    path: 'touchscreen',
    loadChildren: () => import('../../core/touch-screen/touch-screen.module').then(m => m.TouchScreenModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'tablet',
    loadChildren: () => import('../../core/tablets/tablet.module').then(m => m.TabletModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'notification',
    loadChildren: () => import('../../core/notifications/notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'chr',
    loadChildren: () => import('../../core/chr/chr.module').then(m => m.ChrModule)
  },
  {
    path: 'fulle-pos',
    loadChildren: () => import('../../core/fulle-pos/fulle-pos.module').then(m => m.FullePosModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('../../core/documents/documents.module').then(m => m.DocumentsModule)
  },
  {
    path: 'workTime',
    loadChildren: () => import('../../core/worktime/worktime.module').then(m => m.WorktimeModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('../../core/partners/partners-routing.module').then(m => m.PartnersRoutingModule),
    canActivate: [AuthGuard],
    data: { roles: [subscriptions_name.GuestConnect] }
  },
  {
    path: 'order',
    loadChildren: () => import('../../core/fees/fees-routing.module').then(m => m.FeesRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'orders',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'orders' }
];
