import { Component, OnInit,AfterViewInit,OnDestroy,ViewChild,ElementRef,ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import stripe from 'stripe';
import { SubscribeService } from '../subscribe.service';
import { Subscribe } from '../subscribe.model';
import { TranslateService } from '@ngx-translate/core';
import { NgxIzitoastService } from 'ngx-izitoast';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-paiment',
  templateUrl: './paiment.component.html',
  styleUrls: ['./paiment.component.scss'],
  providers: [AngularStripeService,ConfirmationService],
  exportAs: 'paiment'
})
export class PaimentComponent implements AfterViewInit, OnDestroy ,OnInit{

    @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  
    stripe;
    loading = false;
    confirmation;
  
    card: any;
    cardHandler = this.onChange.bind(this);
    error: string;
    trial:boolean;
    subscribe:Subscribe;
    constructor(
      private cd: ChangeDetectorRef,
      private stripeService:AngularStripeService,
      private subscribeService: SubscribeService,
      public iziToast: NgxIzitoastService, private confirmationService: ConfirmationService, 
      private translate: TranslateService,private router:Router) {
        
      }

      ngOnInit() {
       this.subscribeService.trial.subscribe(trial => {
           this.trial = trial;
       })
    }
  
    ngAfterViewInit() {
    const style = {
        base: {
            lineHeight: '24px',
            fontFamily: 'monospace',
            fontSmoothing: 'antialiased',
            fontSize: '19px',
            '::placeholder': {
            color: 'purple'
            }
        }
    };
      
      this.stripeService.setPublishableKey('pk_test_51HwlomCQK7R3024GUBjmlVvQ0fwgVj5G6xnzDFui3HdZP4oIHPNL0mJ2qcsyyPJ9IekXAxt5FZiFfAptiJvEL7Ct00qDq1PkbI').then(
        stripe=> {
          this.stripe = stripe;
      const elements = stripe.elements();    
      this.card = elements.create('card',{hidePostalCode: true, style: style});
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
      });
    }
  
    ngOnDestroy() {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  
    onChange({ error }) {
      if (error) {
        this.error = error.message;
      } else {
        this.error = null;
      }
      this.cd.detectChanges();
    }
  
    async onSubmit(form: NgForm) {
    const { token, error } = await this.stripe.createToken(this.card);

    this.subscribe  = {trial: this.trial , token:token.id} ;
    this.loading = true;
    this.subscribeService.userSubscribe(this.subscribe).subscribe(item => {
      
       if(item.success) {
        this.iziToast.success({
          message: this.translate.instant('global.stripe_success'),
          position: 'topCenter',
          toastOnce: true
      });

      this.loading = false;
      this.subscribeService.setUrlStripe(item.url);

          setTimeout(() => {
            this.router.navigate(['subscribe']);
         }, 5000);
       }

    }, (error) => {
      this.loading = false;
      throw error;
    });

    if (error) {
        //console.log('Error:', error);
    } else {
        //console.log('Success!', token);
    }
    }

 
  }