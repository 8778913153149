import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ApiRoutingModule} from './api-routing.module';
import {OrdersListComponent} from './orders-list/orders-list.component';
import { ApiService } from './api.service';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [
      OrdersListComponent
  ],
    providers: [ApiService],
  imports: [
    CommonModule,
    ApiRoutingModule,
    TranslateModule,
    
  ]
})
export class ApiModule { }
