import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CarteService {
    carte: any[] = [];
  constructor() { }
  public EmptyCarte(){
      this.carte = [];
  }
  selectedCountry: any;

  setSelectedCountry(country: any) {
    this.selectedCountry = country;
  }

  getSelectedCountry() {
    return this.selectedCountry;
  }
}
