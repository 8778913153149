import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { Subscribe } from '../subscribe.model';
import { NgxIzitoastService } from 'ngx-izitoast';
import { SubscribeService } from '../subscribe.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss']
})
export class ChoosePlanComponent implements OnInit {
  cols: {};
  subscribe: Subscribe;
  respons: any[];
  responsCheck: any[] = new Array;
  prices: any[] = new Array;
  loading = false;
  selected_opition: any[];
  show: boolean = false;
  isFreeTrial: any[];
  isActive: boolean = false;
  percentageDiscount: number[] = [0, 0];
  showAddCoupon:boolean;
  couponKey:String=null;
  loadingCouponsCheck=false;
  validCouponKey=null;
  couponError = false;
  freeMonths:number=0;
  constructor(private subscribeService: SubscribeService, public iziToast: NgxIzitoastService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.subscriptionsLists();
    this.selected_opition = [];
  }


  choosePlanTarif(value) {
    this.subscribeService.setTrial(value);
  }

  subscriptionsLists() {
    this.subscribeService.subscriptionsLists().subscribe(item => {
      this.respons = item.subscriptions;
      this.respons = this.respons.map(sub => {
        sub['quantity']=0;
        return sub;
      })
      this.isFreeTrial = item.isFreeTrial;
      this.isActive = item.isActive;
      this.percentageDiscount = item.percentageDiscount;
      //this.link = item.link.url;
      this.respons.forEach(res => {
        if(res.name == "GuestTableOrder" || res.name == "GuestOnlineOrder" ){
          this.add_remove(res);
        }
        if(res.name == "GuestTablette" ){
          this.add_remove(res);
        }
      });
    });
   
  }
  
  toggleEditable(event) {

    if (event.target.checked && !this.responsCheck[event.target.id]) {
      this.responsCheck[event.target.id] = event.target.value;
    } else if (!event.target.checked && this.responsCheck[event.target.id]) {
      this.responsCheck[event.target.id] = "";
    }

  }

  extract_element_price_id(id) {



  }
  checkout() {
    this.prices = [];
    this.selected_opition.forEach((currentValue, index) => {
      if (currentValue) {
        this.respons.forEach((val, index) => {
          if (currentValue === val.name) {
            this.prices.push({ price: val.price_id, quantity: 1 });

          }
        });
      }
    });
    if (this.prices.length < 1) {
      this.iziToast.success({
        message: "Veuillez choisir un plan !",
        position: 'topCenter',
        toastOnce: true
      });
      return false;
    }
    // adding non reuquired plan
    this.respons.map(subscription => {
      if(subscription.name=="GuestTablette" && subscription.quantity>0)
      this.prices.push({ price: subscription.price_id, quantity: subscription.quantity});
    })
    this.cols = { prices: this.prices , freePeriod : this.validCouponKey };
    this.loading = true;
    //console.log(this.cols);
    this.subscribeService.userSubscribe(this.cols).subscribe(item => {


      this.loading = false;
      if (item.sessionUrl) {
        window.location.href = item.sessionUrl;
      }

    }, (error) => {
      this.loading = false;
      throw error;
    });

  }

  check_if_is_selected() {
    var a = false;
    this.selected_opition.forEach((currentValue, index) => {

      if (currentValue) {
        this.respons.forEach((val, index) => {
          if (currentValue === val.name) {
            //console.log(val);
            if (val.required) {

              a = true;
            }

          }
        });




      }

    });
    return a;

  }

  add_remove(ele) {
    //console.log(ele);
    var existe = false;
    var index = 0;
    this.selected_opition.forEach(function (value, indexx) {
      if (value == ele.name) {
        existe = true;
        index = indexx;
      }
    });
    if (existe) {
      //remove it
      this.selected_opition.splice(index, 1);
    } else {
      this.selected_opition.push(ele.name);
    }
    this.show = this.check_if_is_selected();
    if (!this.show) this.selected_opition = [];
  }

  Calc_total() {
    let total = 0;
    this.selected_opition.forEach(element => {
      let e = this.respons.find(op => op.name == element)
     
      total += e.price;
    });
    this.respons.map(res => {
      if(res.name =="GuestTablette" && res.quantity > 0)  total +=res.quantity*res.price;
    })
    return total;
  }
  Calc_total_reduction() {
    let reduction =(this.selectedSubscription() >1) ?  this.percentageDiscount[1]: this.percentageDiscount[0];
    return this.Calc_total()-(this.Calc_total() * 0.01  * reduction);
  }
  checkCoupon(){
    this.subscribeService.checkCoupon(this.couponKey).subscribe(item => {
      if(item.check) {
        this.validCouponKey = this.couponKey;
        this.freeMonths = item.freeTrial;
        this.loadingCouponsCheck = false;
        this.couponError=false;
      }
    },(error) => {
      //console.log(error);
      this.loadingCouponsCheck = false;
      this.validCouponKey = null;
      this.couponError=true;

    });
  }
  cancelCoupon(){
    this.showAddCoupon=false
    this.loadingCouponsCheck = false;
    this.validCouponKey = null;

  }
  addQuantityToSubscription(res:any){
    if(res.quantity > 0){
      res.quantity = 0
    }else{
      res.quantity = 1;
    }
 
  }
  removeQuantityToSubscription(res:any){
    if (res.quantity > 0)
    res.quantity--;
  }

  selectedSubscription():number{
    let count = this.selected_opition.length;
    if(count==0){
      return count;
    }else{
      this.respons.map(subscription => {
        if(subscription.name=="GuestTablette" && subscription.quantity>0)
        count++;
      })
    }
    //console.log("COUNT",count);
    return count;
  }
}
