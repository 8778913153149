import { Component, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/authentification/authentification.model';
import { SessionManagerService } from 'src/app/core/session-manager/session-manager.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  showProfil = false;
  showFlags = false;
  user: User = null;
  defaultLang = "fr";
  link:'';


  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.showProfil = false;
      this.showFlags = false;
    }
  }

  constructor(private router: Router, private eRef: ElementRef,private window: Window,private sessionManagerService:SessionManagerService,
    private translate: TranslateService) {
    this.user = JSON.parse(localStorage.getItem(environment.currentUserIndex));
    this.defaultLang = localStorage.getItem("lang");
  }

  ngOnInit() {
    this.AcountStripe();
  }

  AcountStripe() {
    this.sessionManagerService.getAcountStripe().subscribe(Acounstripe => {
      
      this.link = Acounstripe.url;
      if(Acounstripe.AccountStatus){
        this.link = '';
      }
 })
  }

  toggleProfil() {
    this.showProfil = !this.showProfil;
    this.showFlags = false;
  }
  toggleFlags() {
    this.showFlags = !this.showFlags;
    this.showProfil=false;
  }

  logout() {
    localStorage.removeItem(environment.currentUserIndex);
    localStorage.removeItem(environment.tokenIndex);
    localStorage.removeItem(environment.profileCompletedIndex);
    this.router.navigate(['auth']);
  }
  changeLang(lang){
    this.translate.use(lang);
    localStorage.setItem("lang",lang);
    this.defaultLang= lang
    this.showFlags=false;
  }

  closeNavigator() {
    this.window.window.open('','_parent','');
    this.window.window.close();
  }


}

