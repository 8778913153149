import {animate, state, style, transition, trigger} from '@angular/animations';


export let blinking  = trigger('blinking', [
    state('false', style({ opacity: 0 })),
    state('0', style({ opacity: 1 })),
    state('1', style({ opacity: 0 })),
    transition('* <=> *', [
        animate(2000)
    ])
]);

export let removeitem = trigger('remove_item',[

    transition(':enter', [
        style({  transform: 'translateY(100%)'}),
        animate('.2s ease-in', style({ transform: 'translateY(0%)', }))
    ]),
    transition(':leave', [
        style({  transform: 'translateY(0%)',   }),
        animate('.2s ease-out', style({ transform: 'translateY(-100%)'  }))
    ])
]) ;

export let right_to_left = trigger('from_right_to_left',[

    transition(':enter', [
        style({  transform: 'translateX(100%)'}),
        animate('.5s ease-in', style({ transform: 'translateX(0%)' }))
    ]),
    transition(':leave', [
        style({  transform: 'translateX(0%)' }),
        animate('.5s ease-out', style({ transform: 'translateX(100%)' }))
    ])
]) ;

export let left_to_right= trigger('from_left_to_right',[

    transition(':enter', [
        style({  transform: 'translateX(-100%)'}),
        animate('.5s ease-in', style({ transform: 'translateX(0%)' }))
    ]),
    transition(':leave', [
        style({  transform: 'translateX(0%)' }),
        animate('.5s ease-out', style({ transform: 'translateX(-100%)' }))
    ])
]);

export let change = trigger('change',[

    transition(':enter', [
        style({  opacity: 0}),
        animate('.5s ease-in', style({ opacity: 1}))
    ]),
    transition(':leave', [
        style({ opacity: 1}),
        animate('.5s ease-out', style({opacity: 0 }))
    ])
]);
