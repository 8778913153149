import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Deliveryfees, OrderSettings } from './delivery-fees/delivery-fees.model';

@Injectable({
    providedIn: 'root'
})
export class FeesService {

    private dns = environment.dns;

    constructor(private _http: HttpClient) { }

    /**
     * --------------------------------------------------------------
     *
     * GET CALLS
     *
     * --------------------------------------------------------------
     **/
    // public getAll(): Observable<Chr> {
    //   let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //   return this._http.get(this.dns + 'chr_caise', {
    //     headers
    //   }).pipe(map((payload: any) => {
    //     if (payload) {
    //       return payload.chr_caise;
    //     }
    //     return [0];
    //   }));
    // }
    /*
    public getAll(): Observable<Chr[]> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.get(this.dns + 'chr_caise', {
            headers
        }).pipe(map((payload: any) => {
            if (payload.chr_caises) {
                return payload.chr_caises;
            }
            return [];
        }));
    }
*/

    public updateDeliveryFees(payload: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.put(this.dns + 'DeliveryFees', payload, {
            headers
        });
    }

    public getAll(): Observable<Deliveryfees> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.get(this.dns + 'DeliveryFees', {
            headers
        }).pipe(map((payload: any) => {

            if (payload.deliveryFee) {
                return payload.deliveryFee;
            }
            return [];
        }));
    }

    public getOrderSettings(): Observable<OrderSettings> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.get(this.dns + 'restaurants/restaurant_settings', {
            headers
        }).pipe(map((payload: any) => {

            if (payload.restaurant) {
                return payload.restaurant;
            }
            return [];
        }));
    }

    public updateOrderSettings(payload: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.put(this.dns + 'restaurants/min_order', payload, {
            headers
        });
    }

}
