import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  defaultLang = 'fr';
  title: any;

  constructor(translate: TranslateService, private primengConfig: PrimeNGConfig) {
    // let's try to read if we have already stocked data
    var lang= localStorage.getItem("lang")
    if (lang && (lang == "fr" || lang == "en")){
      this.defaultLang = lang
    }else{
      localStorage.setItem("lang","fr")
    }
    translate.setDefaultLang(this.defaultLang);
    this.primengConfig.ripple = true;
  }

}
