import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Restaurant, Adresses, Subscriptions } from './session-manager.model';
import { Ingredients, Product } from '../orders/orders.model';
import { BankImage, Category, Position, Supplements } from '../products/category.model';

@Injectable({ providedIn: 'root' })
export class SessionManagerService {

  private dns = environment.dns;

  constructor(private _http: HttpClient) { }

  isProfileCompleted() {
    let profileCompleted = localStorage.getItem(environment.profileCompletedIndex);
    if (profileCompleted == "true") return true;
    return false;
  }

  /**
   * --------------------------------------------------------------
   *
   * GET CALLS
   *
   * --------------------------------------------------------------
   **/
  public getSpecialities(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'specialities', {
      headers
    }).pipe(map((payload: any) => payload.specialities));
  }

  public getCities(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'cities', {
      headers
    }).pipe(map((payload: any) => payload.cities));
  }

  public getCitiesByName(name: string, country: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { "name": name, "country_code": country };
  
    return this._http.post<any>(this.dns + 'cities/likeName', body, { headers });
  }
  
  public getCityByZipCode(zipcode): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'cities/zipcode/' + zipcode, {
      headers
    }).pipe(map((payload: any) => payload.city));
  }

public getCountries(): Observable<any[]> {
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this._http.get(this.dns + 'countries', { headers })
      .pipe(map((payload: any) => payload.countries));
}

  public getCurrentRestaurant(): Observable<Restaurant> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'restaurant', {
      headers
    }).pipe(map((payload: any) => payload.restaurant));
  }

  public getAcountStripe(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'checkstripe', {
      headers
    }).pipe(map((payload: any) => {
      if (payload) {
        return payload;
      }
      return [];
    }));
  }

  public UpdateApiKeye(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'restaurants/generateKey', {
      headers
    }).pipe(map((payload: any) => {
      if (payload) {
        return payload;
      }
      return [];
    }));
  }
  /**
   * --------------------------------------------------------------
   *
   * UPDATE CALLS
   *
   * --------------------------------------------------------------
   **/
  public updateCurrentRestaurant(restaurant: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.put(this.dns + 'restaurants', restaurant, {
      headers
    });
  }


  // addresses CRUD


  public getAllAddresses(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'cacaddress', {
      headers
    }).pipe(map((payload: any) => {
      if (payload.cacAddresses) {
        return payload.cacAddresses;
      }
      return [];
    }));
  }

  public addAddress(payload: Adresses): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(this.dns + 'cacaddress', payload, {
      headers
    });
  }

  public updateAddress(payload: Adresses): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.put(this.dns + 'cacaddress/' + payload.id, payload, {
      headers
    });
  }

  public deleteAddress(id): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.delete(this.dns + 'cacaddress/' + id, {
      headers
    });
  }

  public updateSubscriptions(subscriptions: Subscriptions[]): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.put(this.dns + 'subscriptions', { subscriptions }, {
      headers
    });
  }
  public getSubscriptions(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'subscriptions/options', {
      headers
    });
  }
  public getInvoice(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'getInvoice', {
      headers
    });
  }
  public suspendSubscription(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'stopSubscription', {
      headers
    });
  }
  public resumeSubscription(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'resumeSubscription', {
      headers
    });
  }
  public changeStatusMode(payload:any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.put(this.dns + 'restaurants/statusmode', payload,{
      headers
    });
  }
  // Createbankimages
  public getAllProducts(): Observable<Product[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'restaurants/products', {
      headers
    }).pipe(map((payload: any) => {
      if (payload.restaurant && payload.restaurant[0] && payload.restaurant[0].products) {
        return payload.restaurant[0].products;
      }
      return [];
    }));
  }
  public addProduct(payload: Product): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(this.dns + 'products', payload, {
      headers
    });
  }
  public updateProdcut(payload: Product): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.put(this.dns + 'products/' + payload.id, payload, {
      headers
    });
  }
  public enableDisable(id: number, type: boolean): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.put(this.dns + 'products/changeStatus/' + id, { "isActive": type }, {
      headers
    });
  }
  public deleteProduct(id): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.delete(this.dns + 'products/' + id, {
      headers
    });
  }
  public addProductToDayMenu(id): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'dailymenu/addproduct/' + id, {
      headers
    });
  }
  public getMenuOfDay(): Observable<Product[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'restaurants/dailymenuproducts', {
      headers
    }).pipe(map((payload: any) => {
      if (payload.productsDailyMenu) {
        return payload.productsDailyMenu;
      }
      return [];
    }));
  }
  public updateIngredients(payload: Product): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.put(this.dns + 'ingredients/' + payload.id, payload, {
      headers
    });
  }

  public updateProductPosition(products: Position[]): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = { products };
    return this._http.put(this.dns + 'products/positions', params, {
      headers
    });
  }
  public getAllMenusProduct(): Observable<any[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'menus', {
      headers
    }).pipe(map((payload: any) => {
      if (payload.menus) {
        return payload.menus;
      }
      return [];
    }));
  }

  public addMenusProduct(payload: Supplements): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(this.dns + 'menus', payload, {
      headers
    });
  }

  public updateMenusProduct(payload: Supplements): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.put(this.dns + 'menus/' + payload.id, payload, {
      headers
    });
  }

  public deleteMenusProduct(id): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.delete(this.dns + 'menus/' + id, {
      headers
    });
  }
  public deleteGroupProduct(ids): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post(this.dns + 'products/delete', { products: ids }, { headers });
  }
  public updateMultiple(payload: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.put(this.dns + 'products/updateMulti', payload, {
      headers
    });
  }
  public getProdImages(): Observable<BankImage[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'image_products/products', {
      headers
    }).pipe(map((payload: any) => {
      if (payload.image_products) {
        return payload.image_products;
      }
      return [];
    }));
  }
  public getAllIngredient(): Observable<Ingredients[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'ingredients', {
      headers
    }).pipe(map((payload: any) => {
      if (payload.ingredients) {
        return payload.ingredients;
      }
      return [];
    }));
  }
  public getAll(): Observable<Category[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'restaurants/categories', {
      headers
    }).pipe(map((payload: any) => {
      if (payload.categories) {
        return payload.categories;
      }
      return [];
    }));
  }
  public getAllSupplements(): Observable<Supplements[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'supplements', {
      headers
    }).pipe(map((payload: any) => {
      if (payload.supplements) {
        return payload.supplements;
      }
      return [];
    }));
  }
  public getAllBlocs(): Observable<Supplements[]> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'blocs', {
      headers
    }).pipe(map((payload: any) => {
      if (payload.blocs) {
        return payload.blocs;
      }
      return [];
    }));
  }
  public addImageCategory(imageCategory: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(this.dns + 'image_categories', imageCategory, {
      headers
    });
  }
  public addImageProduct(imageProduct: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(this.dns + 'image_products', imageProduct, {
      headers
    });
  }
  
  



}
