import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})

export class FileUploadComponent {

    @Input()
    multiple = false;

    @Input()
    base64 = true;

    @Output() fileChange = new EventEmitter();

    @Input()
    currentFile = null;
   
    imageError: string;


    constructor(private translate: TranslateService) {

    }

    onFileChange(event) {
        this.imageError = null;
        const file = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            const max_size = 5000000;
            const allowed_types = ['image/png', 'image/jpeg'];

            if (event.target.files[0].size > max_size) {
                this.imageError =
                this.translate.instant('msgErreur.size');

                return false;
            }

            if (!_.includes(allowed_types, event.target.files[0].type)) {
                this.imageError =  this.translate.instant('msgErreur.file_type');
                return false;
            }

        }

        if (this.base64) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.fileChange.emit(reader.result);
                this.currentFile = reader.result;
            };
        } else {
            this.fileChange.emit(file);
            this.currentFile = file;
        }
    }

    clearFile() {
        this.currentFile = null;
        this.fileChange.emit(null);
    }
}
