import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { SubscribeService } from './subscribe.service';
import { SubscribeComponent } from './subscribe.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { RippleModule } from 'primeng/ripple';
import { PaimentComponent } from './paiment/paiment.component';
import { FormsModule } from '@angular/forms';
import { ChoosePlanComponent } from './choose-plan/choose-plan.component';
import { StripeRefreshComponent } from './stripe-refresh/stripe-refresh.component';
import { StripeActiveComponent } from './stripe-active/stripe-active.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {createTranslateLoader} from '../app.module';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';





@NgModule({
    exports: [
        CommonModule,
        SubscribeComponent,
        EmailVerifyComponent,
        PaimentComponent,
        ChoosePlanComponent,
        StripeActiveComponent,
        StripeRefreshComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        RippleModule,
        MessageModule,
        MessagesModule,
        FormsModule,
        TranslateModule

    ],
    declarations: [
      SubscribeComponent,
      EmailVerifyComponent,
      PaimentComponent,
      ChoosePlanComponent,
      StripeActiveComponent,
      StripeRefreshComponent,

    ],
    providers: [SubscribeService]
})
export class SubscribeModule { }
