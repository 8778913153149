import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {interval, Subscription} from 'rxjs';
import { ApiService } from '../api.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.css']
})
export class OrdersListComponent implements OnInit {
    interval = interval(7000);
    intervalSubscription: Subscription;
    Key:string;
    orderRecived:any[];
    orderDone:any[];
  constructor(private route: ActivatedRoute, private apiService: ApiService,private translate: TranslateService) { }

  ngOnInit(): void {
    this.Key=this.route.snapshot.paramMap.get('id');
      this.intervalSubscription = this.interval.subscribe(val => this.load());
  }

  load(){
    // getting orders from
      this.apiService.getAllordersDone(this.Key).subscribe(
          orders => {
            this.orderDone=orders['orders'];
             //console.log(orders);
          },
          (err) => {

          }
      );
      this.apiService.getAllordersReceived(this.Key).subscribe(
          orders => {
              this.orderRecived=orders['orders'];
              //console.log(orders);
          },
          (err) => {

          }
      );

  }
}
