import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiService {

    private dns = environment.dns;

    constructor(private _http: HttpClient) {
    }

    /**
     * --------------------------------------------------------------
     *
     * GET CALLS
     *
     * --------------------------------------------------------------
     **/
    public getAllordersReceived(key: string): Observable<any[]> {
        let headers = new HttpHeaders({'Content-Type': 'application/json'});
        return this._http.get(this.dns + 'v4/ordersReceived/' + key, {
            headers
        }).pipe(map((payload: any) => {
            if (payload) {
                return payload;
            }
            return [];
        }));
    }

    public getAllordersDone(key: string): Observable<any[]> {
        let headers = new HttpHeaders({'Content-Type': 'application/json'});
        return this._http.get(this.dns + 'v4/ordersDone/' + key, {
            headers
        }).pipe(map((payload: any) => {
            if (payload) {
                return payload;
            }
            return [];
        }));
    }

}