import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Restaurant } from 'src/app/core/session-manager/session-manager.model';
import { SessionManagerService } from 'src/app/core/session-manager/session-manager.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    currentDate: Date = new Date();
    restaurant:Restaurant;
    poweredby_logo:any;
    thumbnail: any;
    private cdn = environment.cdn;


    constructor(
        private sessionManagerService:SessionManagerService,
        private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.loadCurrentRestaurant();
    }
    loadCurrentRestaurant() {
        this.sessionManagerService.getCurrentRestaurant().subscribe(restaurant => {
            this.restaurant = restaurant;
            this.poweredby_logo = this.cdn + restaurant.poweredby_logo;
        })
    }
}
