import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subscribe } from './subscribe.model';

@Injectable()
export class SubscribeService {

  private dns = environment.dns;

  private trialSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  trial = this.trialSubject.asObservable();

  private urlStripeSubject: BehaviorSubject<string> = new BehaviorSubject("");
  urlStripe = this.urlStripeSubject.asObservable();


  constructor(private _http: HttpClient,private router: Router) {
  }

  
/**
 * --------------------------------------------------------------
 *
 * GET CALLS
 *
 * --------------------------------------------------------------
 **/
public verifyEmail(id:string,token:string,expires:string,signature:string): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'email/verify/'+ id +'/' + token+ '?expires=' + expires +'&signature='+signature, {
      headers
    }).pipe(map((payload: any) => {
        if (payload) {
             return payload;
        }
        return [];
    }));
  }



  public userSubscribe(subscribe : any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    
    return this._http.post(this.dns + 'subscribe', subscribe, {
      headers
    });
  }



  setTrial = (value: boolean) => {
    this.trialSubject.next(value);
  }

  setUrlStripe = (url : string) => {
     this.urlStripeSubject.next(url);
  }

  public acountStripeActive(id:string): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'return/'+id, {
      headers
    }).pipe(map((payload: any) => {
      //console.log(payload);
        if (payload) {
              if(payload.account == "active"){
             //   this.router.navigate(['auth']);
                
                return payload;
              }
             return payload;
        }
        return [];
    }));
  }

  public subscriptionsLists(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(this.dns + 'subscriptions', {
      headers
    }).pipe(map((payload: any) => {
        if (payload) {
          
              
             return payload;
        }
        return [];
    }));
  }
  public checkCoupon(code): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(this.dns + 'checkFreePeriod ', {freePeriod : code}, {
      headers
    }).pipe(map((payload: any) => {
        if (payload) {
          
              
             return payload;
        }
        return [];
    }));
  }

  


}
