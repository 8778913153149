import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot  } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from 'src/environments/environment';
import {SessionManagerService} from '../core/session-manager/session-manager.service';
import { User } from 'src/app/authentification/authentification.model';
const helper = new JwtHelperService();

@Injectable()
export class AuthGuard implements CanActivate {
    user: User = null;
  constructor(private router: Router,
              private sessionManagerService:SessionManagerService ,
              ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (helper.isTokenExpired(localStorage.getItem(environment.tokenIndex))) {
      this.router.navigate(['auth']);
      return false;
    } else {
      // we need to check permissions by selected option
        // get the user infromation
        this.user = JSON.parse(localStorage.getItem(environment.currentUserIndex));
        if (route.data.roles && this.user ) {
           if (this.checkpermission(route.data.roles)){
             return true;
            }else{
             return false;
            }

        }else{
          return true;



        }

    }
  }
    checkpermission (roles:any[]){
        this.user = JSON.parse(localStorage.getItem(environment.currentUserIndex));
        if(this.user.subscriptions_name){
            for (let i = 0; i < this.user.subscriptions_name.length; i++) {
                if (this.user.subscriptions_name[i].name === roles[0] ){
                    return true;
                }
            }

        }
        return false;

    }
}
