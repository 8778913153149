import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import * as Hammer from 'hammerjs';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { FullLayoutComponent } from './core/full-layout.component';
import { ErrorsHandler } from './shared/errors.handler';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomInterceptor } from './shared/custom.interceptor';
import { NgxIziToastModule } from 'ngx-izitoast';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { SubscribeModule } from './subscribe/subscribe.module';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import { NgxInputRangeModule } from '@ngx-lite/input-range';
import { AccordionModule } from 'primeng/accordion';
import { UberEatComponent } from './core/partners/uber-eat/uber-eat.component';
import { DeliveryFeesComponent } from './core/fees/delivery-fees/delivery-fees.component';
import {CurrencyService} from './currency.service';
import { UberReturnComponent } from './core/partners/uber-eat/uber-return/uber-return.component';
import {ApiModule} from './api/api.module';
import {CarteService} from './carte.service';



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        swipe: {direction: Hammer.DIRECTION_ALL},
    };
}
@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    UberEatComponent,
    DeliveryFeesComponent,
    UberReturnComponent
  ],
  imports: [
    BrowserModule,
    HammerModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    NgxIziToastModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SubscribeModule,
    NgxInputRangeModule,
    AccordionModule,
    ApiModule,
   
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
      CurrencyService,
      CarteService,
      { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
      {
    provide: ErrorHandler,
    useClass: ErrorsHandler

  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomInterceptor,
    multi: true
  },
  {
    provide: LocationStrategy, 
    useClass: PathLocationStrategy
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
