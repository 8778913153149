import { OrdersListComponent } from './orders-list/orders-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':id',
        component: OrdersListComponent
      },

      {
        path: '*',
        redirectTo: '',
        pathMatch: 'full',
      },
      { path: '**', component: OrdersListComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ApiRoutingModule { }
