import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { Subscribe } from '../subscribe.model';
import { SubscribeService } from '../subscribe.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-stripe-refresh',
  templateUrl: './stripe-refresh.component.html',
  styleUrls: ['./stripe-refresh.component.scss']
})
export class StripeRefreshComponent implements OnInit {
  
  subscribe:Subscribe;
  constructor(private subscribeService:SubscribeService, private translate: TranslateService) { }

  ngOnInit(): void {

  }





}
