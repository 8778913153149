import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PartnersService} from '../../partners.service';
import {TranslateService} from '@ngx-translate/core';
import {NgxIzitoastService} from 'ngx-izitoast';

@Component({
  selector: 'app-uber-return',
  templateUrl: './uber-return.component.html',
  styleUrls: ['./uber-return.component.css']
})
export class UberReturnComponent implements OnInit {
  code:string;
  constructor( private route: ActivatedRoute,private partnerservices: PartnersService ,private translate: TranslateService,
               public iziToast: NgxIzitoastService) { }

  ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
          this.code = params['code'];
          this.startintegeration( this.code);
      });
  }
  result:number=0;
    startintegeration(code:string){

        this.partnerservices.sendintegrationcode(code).subscribe(response => {
          //console.log(response);
          if(response.success && response.integration){
              this.result=1;
              this.iziToast.success({

                  message: this.translate.instant('partners.updated')  ,
                  position: 'topCenter',
                  toastOnce: true
              });

          }else{
              this.result=2;
              this.iziToast.warning({

                  message: response.message,
                  position: 'topCenter',
                  toastOnce: true
              });
          }
           // this.loading = false;
            //this.loadCurrentRestaurant();
        }, (error) => {
            this.result=2;
            //console.log(error);
            //this.loading = false;
        });


    }
}
