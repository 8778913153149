import { Injectable } from '@angular/core';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  currency:string;
  user:any;
  constructor() {
      this.user = JSON.parse(localStorage.getItem(environment.currentUserIndex));
      if (this.user ) {
          if (this.user.currency){
              this.currency =  this.user.currency;
          }else{
              this.currency =  '';
          }

      }


  }


}
