import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from '../authentification/auth-guard.service';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { NgxSelectModule } from 'ngx-select-ex';
import {DialogModule} from 'primeng/dialog';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {RatingModule} from 'primeng/rating';
import {InputTextModule} from 'primeng/inputtext';
import {TreeTableModule} from 'primeng/treetable';
import {ContextMenuModule} from 'primeng/contextmenu';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CheckboxModule} from 'primeng/checkbox';

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { AccordionModule } from 'primeng/accordion';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;





@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        TranslateModule,
        FileUploadComponent,
        NgxSelectModule,
        DialogModule,
        TableModule,
        DropdownModule,
        ToastModule,
        ConfirmDialogModule,
        RatingModule,
        InputTextModule,
        RadioButtonModule,
        ContextMenuModule,
        TreeTableModule,
        CheckboxModule,
        AccordionModule
        
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        TranslateModule,
        NgxSelectModule,
        DialogModule,
        TableModule,
        DropdownModule,
        ToastModule,
        ConfirmDialogModule,
        InputTextModule,
        RadioButtonModule,
        ContextMenuModule,
        TreeTableModule,
        CheckboxModule,
        NgxMaskModule.forRoot(),
        AccordionModule
      
       
    
        

    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        FileUploadComponent
    ],
    providers: [AuthGuard, { provide: Window, useValue: window }]
})
export class SharedModule { }
