import { Router } from '@angular/router';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxIzitoastService } from 'ngx-izitoast';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  router: any;
  constructor(public iziToast: NgxIzitoastService, private injector: Injector, private translate: TranslateService) {
  }
  handleError(payload: any) {
    this.router = this.injector.get(Router);
    if (payload instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        this.iziToast.error({ title: 'No Internet Connection', position: 'topCenter', toastOnce: true });
      } else if (payload.error && payload.error.message) {
        if(payload.error.message === "Invalid credentials"){
            this.iziToast.error({ title: this.translate.instant('signin.invalid'), position: 'topCenter', toastOnce: true });
        }else if(payload.error.message === "email already exist!"){
          this.iziToast.error({ title:this.translate.instant('signup.already_exist'), position: 'topCenter', toastOnce: true });
        }  else{
            this.iziToast.error({ title: payload.error.message, position: 'topCenter', toastOnce: true });
        }

      } else {
        // go to login page !
        this.iziToast.error({ title: 'Service not available', position: 'topCenter', toastOnce: true });
       // this.router.navigate(['auth']); If the user is authentificated we are going to stau in infinity loop 
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      //console.log('Handle Client Error (Angular Error, ReferenceError...)');
      //console.log(payload);
    }
  }
}
