import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(localStorage.getItem(environment.tokenIndex)){
      request = request.clone({
        setHeaders: {
          'Authorization': environment.tokenPrefix + localStorage.getItem(environment.tokenIndex)
        }
      });
    }
    return next.handle(request);
  }
}
