export interface Credentials {
  email: string;
  password: string;
}

export interface User {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  email_verified_at?: Date;
  amount?: number;
  restaurant_id?: number;
  created_at?: Date;
  updated_at?: Date;
  subscriptions_name?:any[];
}

export enum subscriptions_name {
    GuestOnlineOrder = 'GuestOnlineOrder',
    GuestTableOrder = 'GuestTableOrder',
    GuestConnect = 'GuestConnect',
    GuestTablePower = 'GuestTablePower',
    GuestPanelOrder = 'GuestPanelOrder',
    GuestTablette = 'GuestTablette',
    GuestReporting = 'GuestReporting',
    GuestResa = 'GuestResa'
}