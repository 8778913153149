import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxIzitoastService } from 'ngx-izitoast';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { SubscribeService } from '../subscribe.service';
import {Message,MessageService} from 'primeng/api';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss'],
  providers: [ConfirmationService,MessageService]
})
export class EmailVerifyComponent implements OnInit {

  id:string; 
  token: string;   
  expires:string;
  signature:string;
  message:string;
  msg1:any;
  constructor(private route: ActivatedRoute, private subscribeService: SubscribeService,
    public iziToast: NgxIzitoastService, private confirmationService: ConfirmationService,
     private translate: TranslateService,private messageService: MessageService,
     private primengConfig: PrimeNGConfig) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.route.paramMap.subscribe(params => {
        this.id = params.get("id");
        this.token = params.get("token");
        this.expires = this.route.snapshot.queryParamMap.get('expires');
        this.signature = this.route.snapshot.queryParamMap.get('signature');


            this.subscribeService.verifyEmail(this.id,this.token,this.expires,this.signature).subscribe(item => {
            this.message = item.message;
            if(item.access_token){ localStorage.setItem(environment.tokenIndex, item.access_token);}
            this.messageService.add({severity:'success', summary: 'Success', detail: this.message});
            this.msg1 = {severity:'success', summary:'Success', detail:'Message Content'};
        });




      });


  }


}
